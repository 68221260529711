var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoaded
    ? _c("detail-page", {
        ref: _vm.detailPageRefName,
        attrs: {
          formModel: _vm.model,
          formSchema: _vm.schema,
          showActionDelete: false,
          tooltipMsg: "pimanualofsekci",
        },
        on: {
          saveForm: function ($event) {
            return _vm.saveModel($event)
          },
          closeDetail: function ($event) {
            return _vm.redirectToTable()
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _vm._v(
                    "\n\t\t" + _vm._s(_vm.$t("detailofthesect")) + "\n\t\t"
                  ),
                  _c("label", [
                    _vm._v(
                      _vm._s(_vm.$t("id")) + " " + _vm._s(_vm.model.MenuId)
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          4282972200
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }