import vueFormGenerator from 'vue-form-generator';
import validatorCommon from '../../services/helpers/validator.common';

export const model = {
	MenuId: 0,
	CsectionId: null,
	MtypeId: null,
	MtypeValueId: null,
	MenuOrder: null,
	MenuActive: true,
	Location: null,
};

export const fields = [
			{
				fields: [
					{
						type: 'select',
						model: 'CsectionId',
						required: true,
						validator: vueFormGenerator.validators.number,
						values: [],
						selectOptions: {
							value: 'CsectionId',
							name: 'CsectionNameSk',
						},
						i18n: {
							label: 'catalogsection3563',
							selectOptions: {
								noneSelectedText: 'select',
							},
						},
					},
					{
						type: 'autocomplete',
						typeSearch: '7',
						model: 'Location',
						required: true,
						validator: validatorCommon.checkEmpty,
						i18n: {
							label: 'locationname',
						},
					},
					{
						type: 'input',
						inputType: 'number',
						min: 0,
						model: 'MenuOrder',
						required: true,
						validator: vueFormGenerator.validators.number,
						i18n: {
							label: 'order2924',
						},
						styleClasses: 'quarter-width',
					},
					{
						type: 'switch',
						model: 'MenuActive',
						default: true,
						i18n: {
							label: 'active:',
							textOn: 'yes2205',
							textOff: 'no2206',
						},
					},
				],
			},
		];
