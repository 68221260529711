<template>
	<detail-page
		v-if="isLoaded"
		:ref="detailPageRefName"
		:formModel="model"
		:formSchema="schema"
		:showActionDelete="false"
		tooltipMsg="pimanualofsekci"
		@saveForm="saveModel($event)"
		@closeDetail="redirectToTable()"
	>
		<template #header>
			{{ $t('detailofthesect') }}
			<label>{{ $t('id') }}&nbsp;{{ model.MenuId }}</label>
		</template>
	</detail-page>
</template>

<script>
import DetailPage from '@/components/general/detail-page';
import { detailPageMixin } from '@/components/general/detail-page.mixin';
import http from '@/services/helpers/http';
import { model, fields } from './section-menu.form-data';

const LocationType2SectionTypeMapping = {
	0: 3, // region
	1: 1, // city
	2: 2, // district
	4: 4, // territory
};
const SectionType2LocationTypeMapping = {
	1: 1, // city
	2: 2, // district
	3: 0, // region
	4: 4, // territory
};

export default {
	components: {
		DetailPage,
	},

	mixins: [detailPageMixin],

	data() {
		return {
			controllerName: 'SectionMenu',
			model,
			schema: {
				groups: fields,
			},
		};
	},

	methods: {
		async loadResources() {
			const [catalogSections] = await Promise.all([http.get('CatalogSection/GetAllVisible').then((res) => res.data)]);

			this.updateSchemaFields({
				CsectionId: { values: catalogSections },
			});
		},

		openDeleteModal() {
			serviceCommon.openDeleteModal(this.model.MenuId, this.$modal, this.deleteModel);
		},

		transformDtoToModel(dtoModel) {
			return {
				...dtoModel,
				Location: { Id: dtoModel.MtypeValueId, Type: SectionType2LocationTypeMapping[dtoModel.MtypeId] },
			};
		},

		transformModelToDto(model) {
			const _model = {
				...model,
				MtypeValueId: model.Location.Id,
				MtypeId: LocationType2SectionTypeMapping[model.Location.Type],
			};

			return _model;
		},
	},
};
</script>